<div class="container-fluid mt-3">
  <h4>Liste des tâches</h4>
    <div class="mb-3 row">
    <div class="contrats-actions col-xs-3 col-sm-auto">
      <button class="btn btn-primary" (click)="newItem()">Ajouter</button>
    </div>

    <form class="col-9">
      <div class="filtre-contrat mb-3 row">
        <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche :</label>
        <div class="col-xs-3 col-sm-auto">
          <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
        </div>
        <div class="col-md-5 form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="scheduled" [value]="true" name="scheduled" [formControl]="scheduled">
          <label for="scheduled"  class="form-check-label">Voir les tâches dans le scheduler</label>
        </div>
      </div>
    </form>
  </div>

  <table class="table table-striped table-bordered table-hover" id="excel-table">
    <thead>
    <tr>
      <th scope="col" sortable="idBatch" (sort)="onSort($event)" style="width: 60px">Id</th>
      <th scope="col" sortable="batchType.libelle" (sort)="onSort($event)" style="width: 320px">Type</th>
      <th scope="col" sortable="description" (sort)="onSort($event)">Description</th>
      <th scope="col" sortable="humanCronTranslation" (sort)="onSort($event)" style="width: 300px">Programmation</th>
      <th scope="col" sortable="actif" (sort)="onSort($event)" style="width: 80px">Actif</th>
      <th scope="col" style="width: 212px">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let task of tacheListObs | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" #em>
      <td><ngb-highlight [result]="task.idBatch | number" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="task.batchType.libelle" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="task.description" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="task.humanCronTranslation" [term]="filter.value"></ngb-highlight></td>
      <td [ngStyle]="{color: task.actif ? 'hsl(141, 53%, 53%)' : 'hsl(348, 100%, 61%)' }"><ngb-highlight [result]="task.actif | booleanToOuiNon" [term]="filter.value"></ngb-highlight></td>
      <td>
        <a class="btn btn-primary" (click)="modify(task)" title="Modifier le traitement"> <i class="fa fa-pencil"></i> </a>
        <a class="btn btn-warning" *ngIf="task.migrated" (click)="start(task)" title="Execution immédiate du traitement"> <i class="fa fa-cogs"></i> </a>
        <a class="btn btn-outline-warning" *ngIf="task.actif" (click)="unSchedule(task)" title="Désactiver le traitement"> <i class="fa fa-stop"></i> </a>
        <a class="btn btn-outline-warning" *ngIf="!task.actif" (click)="schedule(task)" title="Activer le traitement"> <i class="fa fa-play"></i> </a>
        <a class="btn btn-danger" (click)="delete(task)"  title="Supprimer le traitement"> <i class="fa fa-trash"></i> </a>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="align-content-start row">
    <div class="col-auto"><ngb-pagination [pageSize]="pageSize" [collectionSize]="(tacheListObs | async) ? (tacheListObs | async).length : tacheList.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination></div>
  </div>
</div>

