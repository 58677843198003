import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-mon-compte-pro-modal',
  templateUrl: './mon-compte-pro-modal.component.html',
  styleUrls: ['./mon-compte-pro-modal.component.css']
})
export class MonCompteProModalComponent {


  constructor(private oidcSecurityService: OidcSecurityService, public activeModal: NgbActiveModal) {
  }

  login() {
    this.oidcSecurityService.authorize();
  }

}
