import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {Batch} from "../../../models/batch";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";
import {TaskService} from "../../../services/task.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DialogFormTaskComponent} from "../dialog-form-task/dialog-form-task.component";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastTool} from "../../../tools/toast.tool";
import {BatchType} from "../../../models/batch-type";
import {ModalAlertComponent} from "../../../tools/modal-alert/modal-alert.component";

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit{


  pageSize = 10;
  page = 5;
  filter = new FormControl('', {nonNullable: true});
  scheduled = new FormControl(false, {nonNullable: true});
  tacheListObs : Observable<Batch[]>;
  tacheList : Array<Batch> = [];
  batchTypeList:Array<BatchType> = [];
  fromScheduler = false;

  constructor(private taskService: TaskService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.loadList();
    this.loadListType();
    this.scheduled.valueChanges.subscribe(res => {
      this.fromScheduler = res;
      this.loadList();
      this.loadListType();
    })
  }

  loadListType(){
    this.taskService.getTypeList().subscribe({
      next: l => this.batchTypeList = l,
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  loadList(): void {
    this.taskService.getList(this.fromScheduler).subscribe({
      next: (data) => {
        this.tacheList = data;

        this.tacheListObs = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
      }
    });
  }

  search(text: string): Batch[] {
    return this.tacheList.filter((task) => {
      const term = text.toLowerCase();
      return (
        task.crous?.libelle.toLowerCase().includes(term) ||
        task.description?.toLowerCase().includes(term) ||
        task.etablissementClient?.nom.toLowerCase().includes(term) ||
        task.batchType.libelle?.toLowerCase().includes(term)
      );
    });
  }








  newItem() {
    const modalRef = this.modalService.open(DialogFormTaskComponent, { centered: true, size:'xl', backdrop: 'static' });
    modalRef.componentInstance.batchTypeList = this.batchTypeList;
    modalRef.componentInstance.taskModifie.subscribe(() => {
      this.loadList();
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    console.log(column)
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadList();
    } else {
      this.tacheListObs = this.tacheListObs.pipe(map(arr => arr.sort((a, b) => {
        let res;
        if (column == 'batchType.libelle') {
          res = NgbdSortableHeader.compare(a.batchType ? a.batchType.libelle : '', b.batchType ? b.batchType.libelle : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }


  delete(task: Batch) {
    const modal = this.modalService.open(ModalAlertComponent, {backdrop: 'static'})
    modal.componentInstance.title = 'Effacer la tâche';
    modal.componentInstance.messageP1 = 'Vous allez effacer la tâche :';
    modal.componentInstance.messageP2 = task.description;
    modal.componentInstance.messageP2Bold = true;
    modal.componentInstance.messageP3 = 'Etes vous sur ?';
    modal.result.then(
      value => {
        this.taskService.delete(task).subscribe({
          next: l => this.loadList(),
          error: (err: HttpErrorResponse) => {
            for (let error of err.error) {
              let description = error.error_description;
              ToastTool.danger(description);
            }
          }
        });
      }
    ).catch(r => {
    });
  }

  modify(task: Batch) {
    const modalRef = this.modalService.open(DialogFormTaskComponent, { centered: true, size:'xl', backdrop: 'static' });
    modalRef.componentInstance.task = task;
    modalRef.componentInstance.batchTypeList = this.batchTypeList;
    modalRef.componentInstance.idBatchType = task.batchType.idBatchType;
    modalRef.componentInstance.taskModifie.subscribe(() => {
      this.loadList();
    })
  }


  start(task: Batch) {
    this.taskService.start(task).subscribe({
      next: l => this.loadList(),
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  unSchedule(task: Batch) {
    this.taskService.unschedule(task).subscribe({
      next: l => this.loadList(),
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  schedule(task: Batch) {
    this.taskService.schedule(task).subscribe({
      next: l => this.loadList(),
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }




}
