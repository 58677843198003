import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-connexion',
  standalone: true,
  templateUrl: './connexion.component.html',
  imports: [
    NgIf
  ],
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  constructor(private oidcSecurityService: OidcSecurityService, private router : Router,
              private meService: UserService) {
  }

  loaded = false;


  login() {
    this.oidcSecurityService.authorize();
  }

  ngOnInit(): void {
    this.meService.me().subscribe({
      next: me => {
        if (me.nom && me.droitList) {
          this.router.navigate(['/home']);
        } else if (me.nom) {
          this.router.navigate(['/no-habilitation']);
        }
        this.loaded = true;
      }, error : err  => {
        this.router.navigate(['/connect']);
        this.loaded = true;
      }
  })
  }

}
