import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {UserService} from "./services/user.service";
import {filter, of, switchMap} from "rxjs";
import {Me} from "./models/me";
import {MonCompteProModalComponent} from "./menu/mon-compte-pro-modal/mon-compte-pro-modal.component";
import {ToastTool} from "./tools/toast.tool";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  userName = 'Se déconnecter';
  isAuthenticated = false;
  isGranted = false;

  constructor(private readonly oidcSecurityService: OidcSecurityService,
              private readonly meService: UserService) {
  }


  ngOnInit() {

    this.oidcSecurityService.checkAuth().pipe(
      filter(({ isAuthenticated, userData, accessToken, idToken, configId}) => isAuthenticated),
      switchMap(({ isAuthenticated, userData, accessToken, idToken, configId}) => {
          this.isAuthenticated = isAuthenticated;
          if (isAuthenticated) {
            this.userName = this.capitalize(userData.given_name) + ' ' + this.capitalize(userData.family_name);
          }
          return this.meService.me();
        }
      )).subscribe(res => {
      this.isGranted = Me.isGranted(res);
    });


  }

  public capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
