import {Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EtablissementService} from '../../../services/etablissement.service';
import {Crous} from '../../../models/crous';
import {SearchAyantDroitService} from '../search-ayant-droit.service';
import {CrousService} from '../../../services/crous.service';
import {environment} from '../../../../environments/environment';
import {Router, Event, NavigationEnd, ActivatedRoute} from '@angular/router';
import {concat, distinctUntilChanged, Observable, of, Subject, timer} from 'rxjs';
import {SearchErreurInfocartecrousService} from '../search-erreur-infocartecrous.service';
import {ToastTool} from '../../../tools/toast.tool';
import {UserService} from '../../../services/user.service';
import {Me} from '../../../models/me';
import {NgbDateAdapter, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateNativeAdapter} from '../../../tools/datepicker-adapter';
import {NgbDateFRParserFormatter} from '../../../tools/ngb-date-fr-parser-formatter';
import {catchError, switchMap} from "rxjs/operators";
import {EtablissementClient} from "../../../models/etablissement-client";
import {NgSelectComponent} from "@ng-select/ng-select";


@Component({
  selector: 'app-search-ayant-droit-form',
  templateUrl: './search-ayant-droit-form.component.html',
  styleUrls: ['./search-ayant-droit-form.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useFactory: () => {
        return new NgbDateFRParserFormatter();
      }}]
})
export class SearchAyantDroitFormComponent implements OnInit, OnChanges {

  @ViewChild('etabSelect') ngSelectComponent: NgSelectComponent;

  searchForm: UntypedFormGroup;


  formSubmited = false;
  crousList: Array<Crous>;
  rechercheInfocartecrous = false;
  showMenu = true;
  comptecloture = false;
  droitSearchCompteCloture = false;
  droitAdminNat = true;
  me: Me;

  etablissementList :  Observable<Array<EtablissementClient>>;
  etablissementInput = new Subject<string>();


  isCollapsed = true;

  menuCollapse = false;

  constructor(private etablissementService: EtablissementService,
              private fb: UntypedFormBuilder, private crousService: CrousService,
              private router: Router,
              private searchAyantDroitService: SearchAyantDroitService,
              private userService: UserService,
              private route: ActivatedRoute,
              private searchErreurInfocartecrousService: SearchErreurInfocartecrousService) {

    userService.me().subscribe(u => {
      this.me = u;
      if (this.hasDroitDecloture()) {
        this.droitSearchCompteCloture = true;
      }
      this.droitAdminNat = u.adminNational;
    });

    const nom = this.route.snapshot.queryParams['nom'] || '';
    const prenom = this.route.snapshot.queryParams['prenom'] || '';
    let dateNaissance = this.route.snapshot.queryParams['n'];
    if (dateNaissance !== undefined && dateNaissance.length === 8) {
      dateNaissance = dateNaissance.substr(6, 2) + '/' + dateNaissance.substr(4, 2) + '/' + dateNaissance.substr(0, 4);
    }

    this.searchForm = fb.group({
      start: fb.control('0'),
      crous: fb.control('0'),
      dateFinAvant: fb.control(''),
      dateFinApres: fb.control(''),
      etablissement: fb.control(''),
      typeQueryNom: fb.control('eq'),
      nom: fb.control(nom),
      typeQueryPrenom: fb.control('eq'),
      prenom: fb.control(prenom),
      identifiant: fb.control(''),
      typeQueryEmail: fb.control('eq'),
      email: fb.control(''),
      numZdc: fb.control(''),
      ine: fb.control(''),
      numInterne: fb.control(''),
      tarifsForces: fb.control(''),
      typeQueryCodeSociete: fb.control('eq'),
      codeSociete: fb.control(''),
      typeQueryCodeTarif: fb.control('eq'),
      codeTarif: fb.control(''),
      rneEtablissement: fb.control(''),
      rneComposante: fb.control(''),
      sansCarte: fb.control(''),
      avecCarte: fb.control(''),
      sansIne: fb.control(''),
      sansDateNaissance: fb.control(''),
      persoSelected: fb.control('perso1'),
      typeQueryCodePerso: fb.control('eq'),
      perso: fb.control(''),
      typeQueryDateNaissance: fb.control('eq'),
      dateNaissance: fb.control(dateNaissance),
      typeQueryDateCreation: fb.control('eq'),
      dateCreation: fb.control(''),
      typeQueryDateModification: fb.control('eq'),
      dateModification: fb.control(''),
      compteCloture: fb.control(''),
      searchType:fb.control('b4')
    });

    crousService.listCrousAutorises().subscribe(c => this.crousList = c);

    this.selectedCrous(null);

    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/ad/search/ad') {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }

      }
    });

    this.rechercheInfocartecrous = this.getSearchContext() === 'infoc';


  }

  hasDroitDecloture() {
    return this.me.droitList.some(r => r === 20) || this.me.adminNational;
  }


  ngOnInit() {
  }

  ngOnChanges() {

  }

  getSearchContext(): string {
    const pos = this.router.url.indexOf('/', 2);
    return this.router.url.substring(1, pos);
  }

  publishContext() {
    switch (this.getSearchContext()) {
      case 'ad':
        this.searchAyantDroitService.publishData(this.searchForm);
        break;
      case 'infoc':
        this.searchErreurInfocartecrousService.publishData(this.searchForm);
    }
  }


  selectEtablissement(event) {
    if (event != null) {
      this.searchForm.get('etablissement').setValue(event.idEtablissementClient);
      return;
    }
    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }
    this.searchForm.get('etablissement').setValue(null);
  }

  search() {

    if (!this.testIfCompteClotureSearch()) {
      ToastTool.danger('Vous devez saisir au moins nom ET prenom, email, identifiant ou numéro de carte');
      return;
    }

    this.router.navigate(['/' + this.getSearchContext() + '/search/list']);

    timer(300).subscribe(val => {
      this.formSubmited = true;
      this.publishContext();
      this.formSubmited = false;

    });

  }

  selectedCrous(event) {
    const idCrous = this.searchForm.get('crous').value;
    this.selectEtablissement(null);

    this.etablissementList = concat(
      of([]), // default items
      this.etablissementInput.pipe(
        distinctUntilChanged(),
        switchMap(term => this.etablissementService.listEtabByIdCrous(idCrous, term).pipe(
          catchError(() => of([])), // empty list on error
        ))
      )
    );

  }

  testIfCompteClotureSearch() {
    if (!this.comptecloture) {
      return true;
    }

    if (this.droitAdminNat) {
      return true;
    }

    if (this.searchForm.get('email').value) {
      return true;
    }

    if (this.searchForm.get('identifiant').value) {
      return true;
    }

    if (this.searchForm.get('numZdc').value) {
      return true;
    }

    if (this.searchForm.get('nom').value && this.searchForm.get('prenom').value) {
      return true;
    }


    return false;




  }

  compteCloture() {
    this.comptecloture = this.searchForm.get('compteCloture').value;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
