import {Component, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UtilisateurB4} from "../../models/utilisateur-b4";
import {EtablissementClient} from "../../models/etablissement-client";
import {Crous} from "../../models/crous";
import {CrousService} from "../../services/crous.service";
import {IDropdownSettings} from "../../tools/multiselect";
import {Droit} from "../../models/droit";
import {UserService} from "../../services/user.service";
import {ToastTool} from "../../tools/toast.tool";
import {EtablissementService} from "../../services/etablissement.service";
import {
  arrayNotEmptyValidator, crousValidator,
  dateInFutureButLessThanOneYear,
  requireOneOfTwoList
} from "../../tools/custom-validators";
import {Utils} from "../../tools/Utils";
import {NgbDateAdapter, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {NgbDateNativeAdapter} from "../../tools/datepicker-adapter";
import {NgbDateFRParserFormatter} from "../../tools/ngb-date-fr-parser-formatter";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-utilisateur-edit',
  templateUrl: './utilisateur-edit.component.html',
  styleUrls: ['./utilisateur-edit.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useFactory: () => {
        return new NgbDateFRParserFormatter();
      }}]
})
export class UtilisateurEditComponent implements OnInit{

  action: string = 'créer';
  form: UntypedFormGroup;
  utilisateur = new UtilisateurB4();
  crousList: Array<Crous>; // liste des crous pour liste déroulante
  droitCrousList: Array<Crous> // liste des crous sur lequel l'utilisateur a le droit
  crousDelegationList: Array<Crous>; // liste des crous sur lesquels l'admin regional peut créer des utilisateur

  droitList: Array<Droit>; // liste des droits
  etablissementClientList: Array<EtablissementClient>;

  nbAnneeFinValiditeMax = 1;

  droitAdminNational: boolean;
  droitAdminRegional: boolean;

  dropdownSettingsCrous = {
    singleSelection: false,
    text: "Sélectionner un ou plusieurs Crous",
    defaultOpen: false,
    idField: 'idCrous',
    textField: "libelle",
    selectAllText: "Sélectionner tout",
    unSelectAllText: "Désélectionner tout",
    itemsShowLimit: 30,
    noDataAvailablePlaceholderText: 'Aucun crous',
    allowSearchFilter: false,
    limitSelection: -1
  } as unknown as IDropdownSettings;

  dropdownSettingsDroit = {
    singleSelection: false,
    text: "Sélectionner un ou plusieurs Droits",
    defaultOpen: false,
    idField: 'idDroit',
    textField: "libelleDroit",
    selectAllText: "Sélectionner tout",
    unSelectAllText: "Désélectionner tout",
    itemsShowLimit: 30,
    noDataAvailablePlaceholderText: 'Aucun droits',
    allowSearchFilter: false,
    limitSelection: -1
  } as unknown as IDropdownSettings;

  dropdownSettingsEtablissementclient = {
    singleSelection: false,
    text: "Sélectionner un ou plusieurs établissements",
    defaultOpen: false,
    idField: 'idEtablissementClient',
    textField: "nom",
    selectAllText: "Sélectionner tout",
    unSelectAllText: "Désélectionner tout",
    itemsShowLimit: 30,
    noDataAvailablePlaceholderText: 'Aucun établissement',
    allowSearchFilter: true,
    limitSelection: -1
  } as unknown as IDropdownSettings;


  constructor(private fb: FormBuilder, private utilisateurService: UserService,
              private etablissementService: EtablissementService,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private crousService: CrousService) {
  }

  ngOnInit(): void {

    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    this.utilisateur.dateFinValidite = oneYearFromNow;

    this.loadCrous()
    this.loadDroit()
    this.loadListeEtablissements()
    this.getMe().subscribe(u => {
      this.droitAdminNational = u.adminNational;
      this.droitAdminRegional= u.adminRegional;
      this.utilisateur.crous = u.crous;
      if (this.droitAdminNational) {
        this.nbAnneeFinValiditeMax = 30;
      }

      const id = this.route.snapshot.params['idUtilisateur'];
      if (id) {
        this.action='Modifier';
        this.loadUtilisateur(id);
      } else {
        this.loadForm()
      }

    });
  }

  getMe(){
    return this.userService.me();
  }

  loadForm() {
    this.form = this.fb.group({
      nom: this.fb.control<string>(this.utilisateur.nom, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      prenom: this.fb.control<string>(this.utilisateur.prenom, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      courriel: this.fb.control<string>(this.utilisateur.courriel, [Validators.required, Validators.email,
        Validators.minLength(5), Validators.maxLength(255)]),
      identifiant: this.fb.control<string>(this.utilisateur.identifiant, [Validators.required]),
      typeUser: this.fb.control<number>(this.utilisateur.droitEtablissementClientList && this.utilisateur.droitEtablissementClientList.length > 0 ? 2 : 1),
      dateFinValidite: this.fb.control<string>(this.utilisateur.dateFinValidite.toISOString(), [dateInFutureButLessThanOneYear(this.nbAnneeFinValiditeMax)]),
      visibleParAdminNational: this.fb.control<boolean>(this.utilisateur.visibleParAdminNational),
      crous: this.fb.control<Crous>(this.utilisateur.crous == null ? {idCrous:0} as Crous : this.utilisateur.crous, [crousValidator()]),
      droitList: this.fb.control<Array<Droit>>(this.utilisateur.droitList == null ? [] : this.utilisateur.droitList, [arrayNotEmptyValidator()]),
      droitCrousList: this.fb.control<Array<Crous>>(this.utilisateur.droitCrousList == null ? [] : this.utilisateur.droitCrousList),
      crousDelegationList: this.fb.control<Array<Crous>>(this.utilisateur.crousDelegationList == null ? [] : this.utilisateur.crousDelegationList),
      droitEtablissementClientList: this.fb.control<Array<EtablissementClient>>(this.utilisateur.droitEtablissementClientList == null ? [] : this.utilisateur.droitEtablissementClientList)
    }, { validator: requireOneOfTwoList('droitCrousList', 'droitEtablissementClientList') });
  }

  get typeUser() {
    return this.form.controls['typeUser'].value;
  }

  private loadDroit() {
    this.utilisateurService.getDroits().subscribe( d => {
      this.droitList = d;
    });
  }

  loadListeEtablissements() {
    this.etablissementService.getListeEtablissementClient().subscribe({
      next: (data) => {
        this.etablissementClientList = data;
      },
      error: (err) => ToastTool.dangerReponseApi(err)
    })
  }

  loadCrous() {
    this.utilisateurService.getListCrousDeleguation().subscribe(c => {
      this.crousList = c;
      this.droitCrousList = c;
      this.crousDelegationList = c;
    });

  }

  crousEquality(item1: Crous, item2: Crous) {
    return item1.idCrous === item2.idCrous;
  }

  save() {

    if (!this.form.valid) {
      Utils.validateAllFormFields(this.form);
      return;
    }

    if (this.utilisateur.idUtilisateur) {
      this.put();
    } else {
      this.post();
    }

  }

  post() {
    this.userService.post(this.form.getRawValue()).subscribe({next: res => {
        ToastTool.success("Utilisateur ajouté");
        this.router.navigate(['utilisateurs','list']);
      },
      error: err => {
        ToastTool.dangerReponseApi(err)
      }
    })
  }

  put(){
    this.userService.put(this.form.getRawValue(), this.utilisateur.idUtilisateur).subscribe({next: res => {
        ToastTool.success("Utilisateur ajouté");
        this.router.navigate(['utilisateurs','list']);
      },
      error: err => {
        ToastTool.dangerReponseApi(err)
      }
    })
  }

  resetSelectionLists() {
    this.form.controls['droitCrousList'].setValue([]);
    this.form.controls['droitEtablissementClientList'].setValue([]);
    this.form.controls['crousDelegationList'].setValue([]);
  }

  private loadUtilisateur(id: any) {
    this.userService.getUtilisateur(id).subscribe({next: res => {
        this.utilisateur = res;
        this.utilisateur.dateFinValidite = new Date(res.dateFinValidite);
        this.loadForm()
      },
      error: err => {
        ToastTool.dangerReponseApi(err)
      }
    })
  }
}
