<div class="modal-header">
  <h4 class="modal-title">Evolution du système d'authentification</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p>Pour renforcer la sécurité, le système d'authentification à BeforeIzly évolue vers <b>MonComptePro</b>.</p>
  <p>Afin de migrer facilement votre compte Beforeizly,
    liez le dès aujourd'hui à MonComptePro en cliquant sur le bouton ci dessous</p>
  <p>Si vous n'avez pas encore de compte MonComptePro, il vous sera proposer d'en créer un.</p>
  <p class="text-danger">Prochainement, seul l'authentification MonComptePro sera possible. Si vous n'avez pas fait cette démarche avant, vous ne pourrez plus lier votre compte sans contacter le support.</p>
  <p>
  <div class="text-center">
      <button class="moncomptepro-button" (click)="login()"></button>
    <p>
      <a
        href="https://moncomptepro.beta.gouv.fr/"
        target="_blank"
        rel="noopener noreferrer"
        title="Qu’est-ce que MonComptePro ? - nouvelle fenêtre"
      >
        Qu’est-ce que MonComptePro ?
      </a>
    </p>
  </div>
</div>
