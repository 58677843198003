import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Me} from '../models/me';
import {Eppn} from "../models/eppn";
import {EtablissementClient} from "../models/etablissement-client";
import {UtilisateurB4} from "../models/utilisateur-b4";
import {Droit} from "../models/droit";
import {UntypedFormGroup} from "@angular/forms";
import {Crous} from "../models/crous";
import {UserB4Datatable} from "../models/user-b4-datatable";
import {Observable} from "rxjs";



@Injectable()
export class UserService {


  utilisateurCourant: Me = null;

  constructor(private http: HttpClient) {
  }

  me() : Observable<Me> {
    return this.http.get<Me>(environment.apiB4Url + '/v1/me', { withCredentials: true });
  }

  getEppn(){
    return this.http.get<Array<Eppn>>(environment.apiB4Url + '/v1/utilisateurs/eppn', { withCredentials: true });
  }

  getEtablissement() {
    return this.http.get<Array<EtablissementClient>>(environment.apiB4Url + '/v1/utilisateurs/etablissements', { withCredentials: true });
  }

  getListCrousDeleguation() {
    return this.http.get<Array<Crous>>(environment.apiB4Url + '/v1/utilisateurs/crous-delegation', { withCredentials: true });
  }

  getUtilisateursB4(start, text, column, direction) {
    return this.http.get<UserB4Datatable>(environment.apiB4Url + '/v1/utilisateurs?start=' + start + '&search=' + text + '&column=' + column + '&direction=' + direction, { withCredentials: true });
  }

  getDemandeHabilitation(start, text, column, direction) {
    return this.http.get<UserB4Datatable>(environment.apiB4Url + '/v1/utilisateurs/demandes?start=' + start + '&search=' + text + '&column=' + column + '&direction=' + direction, { withCredentials: true });
  }

  getDroits(){
    return this.http.get<Array<Droit>>(environment.apiB4Url + '/v1/utilisateurs/droits', { withCredentials: true });
  }

  post(user: UntypedFormGroup) {
    return this.http.post(environment.apiB4Url + '/v1/utilisateurs', user, { withCredentials: true });
  }

  put(user: UntypedFormGroup, id: number) {
    return this.http.put(environment.apiB4Url + '/v1/utilisateurs/' + id, user, { withCredentials: true });
  }

  getUtilisateur(id: any) {
    return this.http.get<UtilisateurB4>(environment.apiB4Url + '/v1/utilisateurs/'+id, { withCredentials: true });

  }

  delete(idUtilisateur: number) {
    return this.http.delete(environment.apiB4Url + '/v1/utilisateurs/'+idUtilisateur, { withCredentials: true });

  }

  saveToken(idToken: any) {
    return this.http.post(environment.apiB4Url + '/v1/utilisateurs/idToken', {'idToken': idToken }, { withCredentials: true });
  }
}
