
import {Routes} from '@angular/router';
import {SearchAyantDroitComponent} from './ayant-droit/search-ayant-droit/search-ayant-droit.component';
import {AyantDroitFormComponent} from './ayant-droit/ayant-droit-form/ayant-droit-form.component';
import {DatatableAyantDroitComponent} from './ayant-droit/datatable-ayant-droit/datatable-ayant-droit.component';
import {SearchFromIzlyComponent} from "./ayant-droit/search-from-izly/search-from-izly.component";
import {ApiBoursierComponent} from "./api-boursier/api-boursier.component";
import {LogsApiComponent} from "./logs-api/logs-api.component";
import {DatatableLogsApiComponent} from "./logs-api/datatable-logs-api/datatable-logs-api.component";
import {ListeEmetteursComponent} from "./admin/emetteurs/liste-emetteurs/liste-emetteurs.component";
import {ListeContratsComponent} from "./admin/contrats/liste-contrats/liste-contrats.component";
import {ListeRneComponent} from "./rne/liste-rne/liste-rne.component";
import {ListeEtablissementsComponent} from "./admin/etablissements/liste-etablissements/liste-etablissements.component";
import {ListeCrousComponent} from "./admin/crous/liste-crous/liste-crous.component";
import {ListeDemandeAyantDroitComponent} from "./admin/demande-ayant-droit/liste-demande-ayant-droit/liste-demande-ayant-droit.component";
import {XpollensApiTestComponent} from "./admin/xpollens-api-test/xpollens-api-test.component";
import {AdminComponent} from "./admin/admin.component";
import {TaskListComponent} from "./admin/tasks/task-list/task-list.component";
import {ImportAyantDroitComponent} from "./ayant-droit/import-excel/import-ayant-droit/import-ayant-droit.component";
import {SagaListComponent} from "./saga/saga-list/saga-list.component";
import {SagaComponent} from "./saga/saga.component";
import {SagaAideComponent} from "./saga/saga-aide/saga-aide.component";
import {UtilisateursComponent} from "./utilisateurs/utilisateurs.component";
import {UtilisateurEditComponent} from "./utilisateurs/utilisateur-edit/utilisateur-edit.component";
import {
  UtilisateurEtablissementsComponent
} from "./utilisateurs/utilisateur-etablissements/utilisateur-etablissements.component";
import {UtilisateurListComponent} from "./utilisateurs/utilisateur-list/utilisateur-list.component";
import {ChangeIdentifiantClientComponent} from "./admin/change-identifiant-client/change-identifiant-client.component";
import {
  PublicationAnnoncesListComponent
} from "./admin/publication-annonces/publication-annonces-list/publication-annonces-list.component";
import {
  PublicationAnnonceEditComponent
} from "./admin/publication-annonces/publication-annonce-edit/publication-annonce-edit.component";
import {isAuthenticated} from "./auth/is-authenticated";
import {isGranted} from "./auth/is-granted";
import {ConnexionComponent} from "./connection/connexion/connexion.component";
import {HomeComponent} from "./connection/home/home.component";
import {NoHabilitationComponent} from "./connection/no-habilitation/no-habilitation.component";
import {
  UtilisateurInactifListComponent
} from "./utilisateurs/utilisateur-inactif-list/utilisateur-inactif-list.component";
import {RechercheCarteComponent} from "./ayant-droit/recherche-carte/recherche-carte.component";


export const ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'ng', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent, canActivate: [isAuthenticated, isGranted]},
  { path: 'no-habilitation', component: NoHabilitationComponent  },
  { path: 'connect', component: ConnexionComponent  },

  {
    path: 'utilisateurs', component: UtilisateursComponent,
    children: [
      {
        path: 'inactifs',
        component: UtilisateurInactifListComponent,
        canActivate: [isAuthenticated, isGranted],
        data: {droit: [1000,2000]}
      },
      {
        path: 'edit',
        component: UtilisateurEditComponent,
        canActivate: [isAuthenticated, isGranted],
        data: {droit: [1000,2000]}
      },
      {
        path: 'edit/:idUtilisateur',
        component: UtilisateurEditComponent,
        canActivate: [isAuthenticated, isGranted],
        data: {droit: [1000,2000]}
      },
      {
        path: 'list',
        component: UtilisateurListComponent,
        canActivate: [isAuthenticated, isGranted],
        data: {droit: [1000,2000]}
      },
      {
        path: 'etablissements',
        component: UtilisateurEtablissementsComponent,
        canActivate: [isAuthenticated, isGranted],
        data: {droit: [1000,2000]}
      }
      ]
  },
    { path: 'ad/search', component: SearchAyantDroitComponent,
      children: [
        {
          path: 'list',
          component: DatatableAyantDroitComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1, 2, 7]}
        },
        { path: 'ad',
          component: AyantDroitFormComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1, 2, 7]}
        },
        { path: 'ad/:id',
          component: AyantDroitFormComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1, 2, 7]}
        },
      ]
    },
    { path: 'saga', component: SagaComponent, children: [
        { path: 'aides', component: SagaListComponent,  canActivate: [isAuthenticated, isGranted], data: {droit: [6]} },
        { path: 'aides/:id', component: SagaAideComponent,  canActivate: [isAuthenticated, isGranted], data: {droit: [6]} }
    ]},
    { path: 'parametres', component: AdminComponent,
      children: [
        {
          path: 'emetteurSupport', component: ListeEmetteursComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'contrats', component: ListeContratsComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'rne', component: ListeRneComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'etablissement', component: ListeEtablissementsComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'crous', component: ListeCrousComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'exportizly', component: ListeDemandeAyantDroitComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'xpollens-api', component: XpollensApiTestComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'xpollens-api', component: XpollensApiTestComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'change-identifiants', component: ChangeIdentifiantClientComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'annonces', component: PublicationAnnoncesListComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'annonces/edit', component: PublicationAnnonceEditComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'annonces/edit/:id', component: PublicationAnnonceEditComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        },
        {
          path: 'tache', component: TaskListComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1000]}
        }
      ]},
    {path: 'logsApi', component: LogsApiComponent, children: [
        { path: 'search',
          component: DatatableLogsApiComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1, 2, 7]}
        }
       ]
    },
    { path: 'izly/search', component: SearchFromIzlyComponent, children: [
        { path: 'list', component: DatatableAyantDroitComponent,
          canActivate: [isAuthenticated, isGranted],
          data: {droit: [1, 2]}
        }
      ]
    },
    {
       path: 'boursiers',
       component: ApiBoursierComponent ,
      canActivate: [isAuthenticated, isGranted],
      data: {droit: [1, 2]}
    },
  {
    path: 'import-ad',
    component: ImportAyantDroitComponent ,
    canActivate: [isAuthenticated, isGranted],
    data: {droit: [1, 2]}
  },
  {
    path: 'cards',
    component: RechercheCarteComponent ,
    canActivate: [isAuthenticated, isGranted],
    data: {droit: [1, 2]}
  }
];
