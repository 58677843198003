import {Crous} from './crous';
import {EtablissementClient} from "./etablissement-client";
import {ActivatedRouteSnapshot} from "@angular/router";

export class Me {

  private _idUser: number;
  private _nom: string;
  private _prenom: string;
  private _dateLastConnexion: string;
  private _adminNational: boolean;
  private _adminRegional: boolean;
  private _droitList: Array<number>;
  private _droitCrousList: Array<Crous>;
  private _etablissementClientList: Array<EtablissementClient>;
  private _crous: Crous
  private _oauthId: string


  get idUser(): number {
    return this._idUser;
  }

  set idUser(value: number) {
    this._idUser = value;
  }

  get nom(): string {
    return this._nom;
  }

  set nom(value: string) {
    this._nom = value;
  }

  get prenom(): string {
    return this._prenom;
  }

  set prenom(value: string) {
    this._prenom = value;
  }

  get dateLastConnexion(): string {
    return this._dateLastConnexion;
  }

  set dateLastConnexion(value: string) {
    this._dateLastConnexion = value;
  }

  get adminNational(): boolean {
    return this._adminNational;
  }

  set adminNational(value: boolean) {
    this._adminNational = value;
  }

  get droitList(): Array<number> {
    return this._droitList;
  }

  set droitList(value: Array<number>) {
    this._droitList = value;
  }

  get droitCrousList(): Array<Crous> {
    return this._droitCrousList;
  }

  set droitCrousList(value: Array<Crous>) {
    this._droitCrousList = value;
  }

  get etablissementClientList(): Array<EtablissementClient> {
    return this._etablissementClientList;
  }

  set etablissementClientList(value: Array<EtablissementClient>) {
    this._etablissementClientList = value;
  }

  get adminRegional(): boolean {
    return this._adminRegional;
  }

  set adminRegional(value: boolean) {
    this._adminRegional = value;
  }


  get crous(): Crous {
    return this._crous;
  }

  set crous(value: Crous) {
    this._crous = value;
  }

  get oauthId(): string {
    return this._oauthId;
  }

  set oauthId(value: string) {
    this._oauthId = value;
  }

  public possedeDroit(droitNecessaire: number | Array<number>): boolean {

    if (Array.isArray(droitNecessaire)){
      for (const d of droitNecessaire) {
        if (this.possedeDroit(d)) {
          return true;
        }
      }
    } else {


      let possedeDroit = false;
      if (this.droitList) {
        possedeDroit = this.droitList.some(droit => {
          return droit === droitNecessaire
        });
      }
      if (possedeDroit) {
        return possedeDroit;
      }
      return possedeDroit;

    }

  }

  public static isPossedeDroit(me : Me, droitNecessaire: number | Array<number>): boolean {

    if (Array.isArray(droitNecessaire)){
      for (const d of droitNecessaire) {
        if (Me.isPossedeDroit(me, d)) {
          return true;
        }
      }
    } else {


      let possedeDroit = false;
      if (me.droitList) {
        possedeDroit = me.droitList.some(droit => {
          return droit === droitNecessaire
        });
      }
      if (possedeDroit) {
        return possedeDroit;
      }
      return possedeDroit;

    }

  }

  public static isGranted(me : Me){
    return me && me.droitList && me.droitList.length > 0;
  }

  static isRouteGranted(me: Me, route: ActivatedRouteSnapshot) {

    if (!me || !route) {
      return false;
    }

    // si pas de data => c'est que c'est une page sans droit
    if (!route.data || !route.data['droit']) {
      return true;
    }

    return route.data['droit'].some(item => me.droitList.includes(item));
  }

}
